<template>
    <div id="watch-playlist-view">
        <w-pl></w-pl>
    </div>
</template>

<script>
    import watchPlaylist from '../components/public/watch-playlist'
    export default  {
        components: {
            'w-pl': watchPlaylist
        }
    }


</script>


<style scoped>


</style>