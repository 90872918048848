<template>
	<div id="watch-playlist" class="py-2 px-md-5 px-4">
		<Loader :state="loader_state"></Loader>
		<g-content-header
			:category="category_name"
			:sub-category="pl_data.subcategory_details ? pl_data.subcategory_details.name : ''"
		></g-content-header>
		<div class="row">
			<div class="col-md-8 text-left">
				<p class="pl_title my-1">{{ pl_data.title }}</p>
				<p class="pl_speaker">{{ pl_data.speaker_details ? pl_data.speaker_details.name : '' }}</p>
			</div>
			<div class="col-md-4 text-md-right text-left my-md-0 my-4">
				<ShareNetwork
					v-for="network in networks"
					:network="network.network"
					:key="network.key"
					:title="pl_data.title ? pl_data.title : ''"
					:description="pl_data.description"
					:url="shareURL + $route.path"
				>
					<font-awesome-icon class="text-white h3 mx-3 sharing" :icon="['fab', network.icon]" />
				</ShareNetwork>
			</div>
		</div>
		<div class="row mb-2">
			<div class="col-md-12">
				<p class="pl_desc">{{ pl_data.description }}</p>
			</div>
		</div>

		<div class="row">
			<div class="col-md-8 px-1">
				<div style="position: sticky; top: 2px">
					<iframe id="pl_player" :src="c_playing" class="border-0" rel="0" autoplay="1"></iframe>
					<div class="row mt-2">
						<div class="col-md-4">
							<p class="playlist_detail my-1">
								Total Videos: <span class="playlist_detail_value">{{ pl_list.length }}</span>
							</p>
							<p class="playlist_detail my-1">
								Status: <span class="playlist_detail_value">{{ pl_data.status ? 'Completed' : 'InProgress' }}</span>
							</p>
						</div>
						<div class="col-md-4">
							<p class="playlist_detail my-1">
								Language:
								<span class="playlist_detail_value">{{
									pl_data.language_details ? pl_data.language_details.name : ''
								}}</span>
							</p>
							<p class="playlist_detail my-1">
								Source: <span class="playlist_detail_value">{{ pl_data.source ? pl_data.source : 'N/A' }}</span>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="row">
					<div class="col-md-12 videos_list">
						<b-card
							v-for="(d, index) in pl_list"
							:key="index"
							@click="play_vdo(d.link, index)"
							class="bg-transparent data-card block_cards"
						>
							<b-card-title class="card_title h1 textMaxLine">
								{{ d.title }}
							</b-card-title>
							<div class="data-card-img-wrapper">
								<span v-if="pl_now === index" class="text-white pl_no_spanner px-3 py-2 position-absolute"
									>Now Playing</span
								>
								<span v-else class="pl_no_spanner p-2 position-absolute">{{ index + 1 }}</span>
								<b-card-img
									class="data-card-img position-relative"
									:src="
										d.thumbnail.standard ? d.thumbnail.standard.url : d.thumbnail.default ? d.thumbnail.default.url : ''
									"
									alt="card_img"
								>
								</b-card-img>
							</div>
						</b-card>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loader from '../shared/alhaq_loader'
import config from '../../assets/config'
import gContentHeader from './general-content-header'
export default {
	components: {
		'g-content-header': gContentHeader,
		Loader: Loader,
	},
	data() {
		return {
			networks: [
				{ network: 'twitter', name: 'Twitter', icon: 'twitter', color: '#1da1f2' },
				{ network: 'whatsapp', name: 'Whatsapp', icon: 'whatsapp', color: '#25d366' },
				{ network: 'skype', name: 'Skype', icon: 'skype', color: '#00aff0' },
			],
			loader_state: false,
			pl_list: [],
			pl_data: {},
			pl_now: '',
			shareURL: config.shareURL,
			category_name: '',
			c_playing: 'https://www.googleapis.com/youtube/v3/playlistItems',
		}
	},
	methods: {
		...mapActions(['getPlaylistsWithId', 'getCategoryById', 'getPlaylistsYoutube']),
		play_vdo(link, index) {
			this.pl_now = index
			this.c_playing = link
		},
	},
	watch: {
		'this.$route.params.pl_id': {
			handler: function () {
				this.loader_state = true
				this.getPlaylistsWithId({ id: this.$route.params.pl_id })
					.then((response) => {
						this.pl_data = response.data.data
					})
					.then(() => {
						this.getCategoryById({ id: this.pl_data.subcategory_details.category_id }).then((response) => {
							this.category_name = response.data.data.name
						})
					})
					.then(() => {
						this.getPlaylistsYoutube({
							pl_link: 'https://www.googleapis.com/youtube/v3/playlistItems',
							pl_id: this.pl_data.url,
							api_key: this.pl_data.api_key_details.api_key,
						}).then((response) => {
							this.pl_list = response.data.items.map((item) => {
								return {
									link:
										'https://www.youtube.com/embed/' +
										item.contentDetails.videoId +
										'?list=' +
										this.pl_data.url +
										'&rel=0',
									thumbnail: item.snippet.thumbnails,
									title: item.snippet.title,
									description: item.snippet.description,
								}
							})
							this.c_playing = this.pl_list[0] ? this.pl_list[0].link : null
							this.pl_now = 0
							this.loader_state = false
						})
					})
			},
			immediate: true,
		},
	},
}
</script>

<style scoped>
.textMaxLine {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	height: 30px;
	overflow: hidden;
}
.pl_title {
	font-family: brandFont-bold;
	font-size: 26px;
	color: #28abe1;
	word-break: break-word;
}
.pl_speaker {
	font-size: 18px !important;
	color: #ffffff;
	font-family: brandFont-subcat;
}
.pl_desc {
	font-size: 14px !important;
	color: #ffffff;
	font-family: brandFont-subcat;
}
.pl_speaker {
	color: #28abe1 !important;
}
#pl_player {
	width: 100%;
	max-height: auto;
	height: 380px !important;
}
.card_title {
	color: #28abe1;
	font-family: brandFont-bold;
	font-size: 14px;
	text-align: justify;
	position: relative;
	z-index: 2;
}
.data-card {
	cursor: pointer;
	border: none;
	overflow: hidden;
}
.data-card {
	border-bottom: 1px dashed white !important;
}
.data-card:last-child {
	border-bottom: none !important;
}
.data-card-img-wrapper {
	overflow: hidden;
}
.data-card-img {
	transition: transform 1s ease-in-out;
}
.videos_list {
	border: 1px dashed white !important;
}
.divider-watch-playlist {
	width: 90%;
}
.playlist_detail {
	font-family: brandFont-subcat;
	color: #ffffff;
	font-size: 14px;
}
.playlist_detail_value {
	font-family: brandFont-cat;
	color: #28abe1;
	font-size: 14px;
}
.sharing {
	cursor: pointer;
}
.sharing:hover {
	color: #28abe1 !important;
}
.pl_no_spanner {
	font-family: brandFont-subcat;
	font-size: 12px;
	bottom: 2%;
	left: 0%;
	z-index: 1;
	color: #ffffff;
	border-radius: 5px;
	background-color: #28abe1;
}
</style>
